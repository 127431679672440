'use client';
import blukai from '~/helpers/blukai';
import { Link } from '#/renderer/router';
import {
  handleClicks,
  handleMenuLinksTienda,
} from '~/components/Headers/handlers';

export default function TiendaLink(props) {
  let index = -1;
  const seccionesHeader = props.getSeccionesHeader;
  for (let i = 0; i < seccionesHeader.length; i += 1) {
    if (
      seccionesHeader[i].Descripcion.toLowerCase().trim() === 'tienda' &&
      seccionesHeader[i].Hijos.length > 0
    ) {
      index = i;
      break;
    }
  }
  if (index > -1) {
    return (
      <a
        className="nav--links-trigger"
        onClick={() => {
          handleMenuLinksTienda();
          blukai.function(window, 'portal_home', 'btn_tienda');
        }}
      >
        Tienda
      </a>
    );
  }
  return (
    <Link to="/" className="active" onClick={handleClicks}>
      Tienda
    </Link>
  );
}
