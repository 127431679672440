'use client';
import { handleDisableMenuLinksJSON, handleMenuLinksJSON } from '~/components/Headers/handlers';
import { Fragment } from 'react';

export default function Servicios({ layoutData }) {
  const sectionOptions = [];
  if (layoutData) {
    for (let i = 0; i < layoutData.MenuHeader.length; i += 1) {
      const item = layoutData.MenuHeader[i];
      const subItems = item.Hijos.map((item2, index) => {
        const innerItems = item2.Hijos.map((item3, index) => (
          <li key={index}>
            <a className="target-option" target={item3.Target} href={item3.URL}>
              {item3.Titulo}
            </a>
          </li>
        ));
        return (
          <div key={index} className="container-menu-option">
            <div className="container-menu-option-tittle">
              <a href={item2.URL} target={item2.Target}>
                {item2.Titulo}
              </a>
              {/* <i className="ico-edit"></i> */}
              <img src={`${process.env.NEXT_PUBLIC_ESPOTS}/headerfooter/${item2.Clase}.svg`} alt="" />
            </div>
            <div className="container-menu-option-items">
              <ul>{innerItems}</ul>
            </div>
          </div>
        );
      });

      const element = (
        <Fragment key={i}>
          <a
            className="link-container"
            onMouseEnter={() => {
              handleMenuLinksJSON(item.Titulo);
            }}
            onMouseLeave={() => {
              handleDisableMenuLinksJSON(item.Titulo);
            }}
          >
            {item.Titulo}
            <i className="ico-dropdown"></i>
            <div className="arrow-up"></div>
          </a>
          <div>
            <div
              className={'nav--links-menu nav--links-menu--' + item.Titulo}
              onMouseEnter={() => {
                handleMenuLinksJSON(item.Titulo);
              }}
              onMouseLeave={() => {
                handleDisableMenuLinksJSON(item.Titulo);
              }}
            >
              <div className="container-menu">
                <div className="options-container-menu">{subItems}</div>
              </div>
            </div>
          </div>
        </Fragment>
      );
      sectionOptions.push(element);
    }
  }
  return (
    <>{sectionOptions}</>
    // <a
    //   className="nav--links-trigger"
    //   onClick={() => {
    //     handleMenuLinks();
    //     blukai.function(window, 'portal_home', 'btn_servicios');
    //   }}
    // >
    //   Servicios
    // </a>
  );
}
