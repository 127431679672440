'use client'
import { handleClicks } from '~/components/Headers/handlers';
import blukai from '~/helpers/blukai';
import { iconCart } from '~/helpers/svg';
import CartQuantity from '~/components/Headers/CartQuantity';
import { Link } from '#/renderer/router';

export default function NavCart() {
  return (
    <Link
      to="/cart"
      className="nav--cart"
      onClick={() => {
        handleClicks();
        blukai.function(window, 'portal_home', 'btn_cart');
      }}
    >
      <div>
        {iconCart}
        <CartQuantity />
      </div>
      <span className='cart-tittle'>Mi Carrito</span>
    </Link>
  );
}
